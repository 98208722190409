<template>
  <div class="pa-5">
    <div class="d-flex mb-5">
      <h3>Orders</h3>
      <v-spacer />
    </div>
    <div>
      <v-checkbox v-model="filters.hideIncorrect" label="hide incorrect" dense @change="getOrders()"/>
    </div>
    <v-pagination v-model="filters.page" :length="filters.pages" @input="getOrders({changePage: 1})"/>
    <v-simple-table>
      <thead>
      <tr>
        <th>DONE</th>
        <th>ID</th>
        <th>STATUS</th>
        <th>DATE</th>
        <th>POSITIONS</th>
        <th class="text-right">SHIPPING</th>
        <th class="text-right">TOTAL</th>
        <th>INVOICE</th>
        <th>TRACKING</th>
      </tr>
      </thead>
      <tbody>

      <tr v-for="(order, orderK) in orders" :key="orderK" :class="{
        'order-done': order.done
      }">
        <td>
          <v-checkbox v-model="order.done" @change="changeDone(order)"  />
        </td>
        <td>{{ order.id }}</td>
        <td>{{ order.status }}</td>
        <td>{{ moment(order.createdAt).format('lll') }}</td>
        <td>
          <div v-for="(item, itemK) in order.items" :key="itemK" class="d-flex">
            <v-chip x-small class="mr-2">{{ item.qty }}x</v-chip>
            <v-chip x-small class="mr-2">{{ item.product ? item.product.pn : '' }}</v-chip>
            <div class="text-truncate">{{ item.product ? item.product.pName : '' }}</div>
            <v-spacer />
            <small>$ {{ item.price.toFixed(2) }}</small>
          </div>
        </td>
        <td class="text-right">
          $ {{ order.shippingCost.toFixed(2) }}
        </td>
        <td class="text-right">
          <strong>$ {{ (order.items.reduce((sum, item) => sum + item.price * item.qty, 0) + order.shippingCost).toFixed(2) }}</strong>
        </td>
        <td>
          <div v-if="order.invoices.length > 0">
            <div v-for="(invoice, invoiceK) in order.invoices" :key="invoiceK">
              <v-btn depressed small color="blue" dark :href="invoice.viewUrl" target="_blank">{{ invoice.invoiceNumber }}</v-btn>
            </div>
          </div>
          <v-btn v-else depressed small color="red" dark :to="{name:'invoices-new', query: {fromShopOrder:order.id}}">Create Invoice</v-btn>
        </td>
        <td>
          <div v-if="order.tracking">
            {{ order.tracking.speditor }}
            {{ order.tracking.number }}
          </div>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
    <v-pagination v-model="filters.page" :length="filters.pages" @input="getOrders({changePage: 1})"/>
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate size="200" color="red" width="10"/>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import functions from "../mixins/functions";
import globals from "../mixins/globals";

export default {
  name: "ShopOrders",
  components: {
  },
  mixins: [functions, globals],
  created() {
    this.getOrders();
  },
  data() {
    axios.get()
    return {
      orders: [],
      loading: false,
      filters: {
        page: 1,
        pages: 0,
        hideIncorrect: true
      }
    };
  },
  methods: {
    changeDone (order) {
      axios.post(this.globals.apiUrl + "/shop-orders/list", {
        changeDone: order.id,
        done: order.done
      });
    },
    getOrders(opt) {
      if (!(opt && opt.changePage)) {
        this.filters.page = 1;
      }
      this.loading = true;
      axios.post(this.globals.apiUrl + "/shop-orders/list", this.filters).then(res => {
        this.orders = res.data.orders;
        this.filters.pages = res.data.pages;
        this.filters.page = res.data.page;
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
.order-done {
  background: #ecf9ec;
}
</style>
